import dayjs from 'dayjs';
import Config from '@/config/config';
import { getAuth } from '../../auth/auth.helpers';

import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
Vue.use(VueToast);

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

//import jsonBoletas from '@/mock/mockContratosCcee.json';

const CONFIG = new Config();

export const findByMesRef = async (mesRef, filtro) => {
  var axios = require('axios');
  var data = filtro;

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/contratoccee/findByMesRef`,
    headers: {
      Bearer: auth.token
    },
    params:{
        mesRef: mesRef
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      if (response.data.status) {
        Vue.$toast.success(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      } else {
        Vue.$toast.error(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      }
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      Vue.$toast.error("Erro", {
        position: 'top-right',
        duration: 5000
      });

      return null;
    });
};

export const atualizarRef = async (mesRef) => {
  var axios = require('axios');

  const auth = getAuth();
  var config = {
    method: 'get',
    url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/contratoccee/atualizaRefencia`,
    headers: {
      Bearer: auth.token
    },
    params:{
        mesRef: mesRef
    }
  };

  return axios(config)
    .then(function (response) {
      if (response.data.status) {
        Vue.$toast.success(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      } else {
        Vue.$toast.error(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      }
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      Vue.$toast.error("Erro", {
        position: 'top-right',
        duration: 5000
      });

      return null;
    });
};