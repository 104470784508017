<template>
    <div v-if="dados && !loading">
        <b-table bordered outlined small :fields="fields" :items="dados" responsive class="custom-table" :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc">

            <template #cell(suprimentoIni)="data">
                <p class="data">{{ data.value }}</p>
            </template>
            <template #cell(suprimentoFim)="data">
                <p class="data">{{ data.value }}</p>
            </template>
            <template #cell(vigenciaIniMontante)="data">
                <p class="data">{{ data.value }}</p>
            </template>
            <template #cell(vigenciaFimMontante)="data">
                <p class="data">{{ data.value }}</p>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fields: [
                { key: 'cliqccee', label: 'Cliqccee', sortable: true  },
                { key: 'tipoEnergiaContrato', label: 'Fonte', headerTitle: 'Fonte Energia' },
                { key: 'suprimentoIni', label: 'Supri Ini', headerTitle: 'Suprimento Inicial' },
                { key: 'suprimentoFim', label: 'Supri Fim', headerTitle: 'suprimento Final' },
                { key: 'perfilVendedor', label: 'Vendedor', sortable: true  },
                { key: 'perfilComprador', label: 'Comprador', sortable: true  },
                { key: 'submercado', label: 'Sub', headerTitle: 'Submercado' },
                { key: 'cliqcceeOrigem', label: 'Origem', headerTitle: 'Cliqccee Origem' },
                { key: 'statusContrato', label: 'Status', sortable: true },
                { key: 'referencia', label: 'Referencia', sortable: true },
                { key: 'montanteMWm', label: 'MWM', headerTitle: 'Montante MWm' },
                { key: 'vigenciaIniMontante', label: 'Vig Ini', headerTitle: 'Vigência Inicial' },
                { key: 'vigenciaFimMontante', label: 'Vig Fim', headerTitle: 'Vigência Final' },
                { key: 'modulacao', label: 'MOD', headerTitle: 'Modulação' },
                { key: 'statusModulacao', label: 'Status M', headerTitle: 'Status Modulação', sortable: true }],
                sortBy: 'cliqccee',
                sortDesc: false
        }
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        dados: {
            type: Array,
            default: null
        }
    }
};
</script>

<style scoped>
.data {
    width: 70px;
}

.custom-table {
    font-size: 12px;
}
</style>